
import React from "react";
import { Link } from "react-router-dom";

const NewFooter = () => {
   
    return (

        <section className="mt-131 mb-40 ">
            <div className="innercontainer">
                <div className="footer-box">
                    <div className="footer-box-detail">
                        <div className=" text-center wrap-btn-footer wrap-btn">
                            {/* <button className="join-us btn btn-outlinenew btn-lg mt-35">
                  <span className="text-white">Join us</span>
                </button> */}
                            <h1 className="footer-heading mt-20">
                                Ready to Boost Your Team's Efficiency?
                            </h1>
                            <Link className=" btn-lg-footer btn btn-primary btn-lg  mt-20" to="/register">
                                Get Started now
                            </Link>
                        </div>
                    </div>
                    <div>
                        <hr className="mt-35 hr-seprator" />
                        <nav className="navigation footer-box-detail">
                            <div className="nav-section">
                                <h4>Uniteam</h4>
                                <ul>
                                    {/* <li>Pricing</li>
                                    <li>Help Center</li>
                                    <li>About</li>
                                    <li>Contact</li> */}
                                    {/* all should be in new line */}
                                    {/* <Link to="/pricing" className="text-white">Pricing</Link><br /> */}
                                    <Link to="/help-guide" className="text-white">Help Center</Link><br />
                                    <Link to="/about-us" className="text-white">About</Link><br />
                                    {/* <Link to="/contact" className="text-white">Contact</Link><br/> */}
                                    <Link to="/support" className="text-white">Support</Link><br/>
                                    <Link to="/documentation" className="text-white">Documentation</Link><br/>
                                    <Link to="/blogs" className="text-white">Blog</Link>
                                </ul>
                            </div>
                            {/* <div className="nav-section">
                                <h4>Downloads</h4>
                                <ul>
                                    <li>Android</li>
                                    <li>iOS</li>
                                </ul>
                            </div>
                            <div className="nav-section">
                                <h4>Solutions</h4>
                                <ul>
                                    <li>AI Chat</li>
                                    <li>All Tasks</li>
                                </ul>
                            </div> */}
                            <div className="nav-section">
                                <h4>Email us</h4>
                                <ul>
                                    {/* <li>support@uniteam.io</li>
                                    <li>sales@uniteam.io</li> */}
                                    <p><span className="fachat"><a href="mailto:support@uniteam.io" className="text-white">support@uniteam.io</a></span><br /><span className="fabriefcase"><a href="mailto:support@uniteam.io" className="text-white">sales@uniteam.io</a></span></p>
                                </ul>
                            </div>
                            <div className="nav-section nav-section-declearation ">
                                <ul>
                                    <li>© 2024 Uniteam All Rights Reserved </li>
                                    {/* <li>Terms of service | Privacy policy</li> */}
                                    <Link to="/terms-of-use" className="text-white">Terms of Use</Link> | <Link to="/privacypolicy" className="text-white">Privacy policy</Link>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default NewFooter;