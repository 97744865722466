import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import ContactUsSection from './components/ContactUsSection'
import Footer from './components/Footer'
import Header from './components/Header'
import ReactPaginate from 'react-paginate';
import NewFooter from './components/newFooter'

const BlogsList = () => {

    const scrolltoRef = useRef(null)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [newBlogList, setNewBlogList] = useState([]);

    const blogdata = [
        {
            img: "images/blog/blog1.png",
            headeing: "20 Best Time Management Tools To Boost Productivity in 2022",
            date: "Oct 27, 2022",
            details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam sit enim nunc aliquet egestas sed convallis eget. Imperdiet sagittis vitae feugiat pharetra quam..."
        },
        {
            img: "images/blog/blog2.png",
            headeing: "20 Best Time Management Tools To Boost Productivity in 2022",
            date: "May 27, 2022",
            details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam sit enim nunc aliquet egestas sed convallis eget. Imperdiet sagittis vitae feugiat pharetra quam..."
        },
        {
            img: "images/blog/blog2.png",
            headeing: "20 Best Time Management Tools To Boost Productivity in 2022",
            date: "Sep 27, 2022",
            details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam sit enim nunc aliquet egestas sed convallis eget. Imperdiet sagittis vitae feugiat pharetra quam..."
        },
        {
            img: "images/blog/blog1.png",
            headeing: "20 Best Time Management Tools To Boost Productivity in 2022",
            date: "Feb 27, 2022",
            details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam sit enim nunc aliquet egestas sed convallis eget. Imperdiet sagittis vitae feugiat pharetra quam..."
        },
        {
            img: "images/blog/blog1.png",
            headeing: "20 Best Time Management Tools To Boost Productivity in 2022",
            date: "Apr 27, 2022",
            details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam sit enim nunc aliquet egestas sed convallis eget. Imperdiet sagittis vitae feugiat pharetra quam..."
        },
        {
            img: "images/blog/blog1.png",
            headeing: "20 Best Time Management Tools To Boost Productivity in 2022",
            date: "Jan 27, 2022",
            details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam sit enim nunc aliquet egestas sed convallis eget. Imperdiet sagittis vitae feugiat pharetra quam..."
        },
        {
            img: "images/blog/blog1.png",
            headeing: "20 Best Time Management Tools To Boost Productivity in 2022",
            date: "Feb 27, 2022",
            details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam sit enim nunc aliquet egestas sed convallis eget. Imperdiet sagittis vitae feugiat pharetra quam..."
        },
        {
            img: "images/blog/blog1.png",
            headeing: "20 Best Time Management Tools To Boost Productivity in 2022",
            date: "March 27, 2022",
            details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam sit enim nunc aliquet egestas sed convallis eget. Imperdiet sagittis vitae feugiat pharetra quam..."
        },
        {
            img: "images/blog/blog1.png",
            headeing: "20 Best Time Management Tools To Boost Productivity in 2022",
            date: "May 27, 2022",
            details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam sit enim nunc aliquet egestas sed convallis eget. Imperdiet sagittis vitae feugiat pharetra quam..."
        },
        {
            img: "images/blog/blog1.png",
            headeing: "20 Best Time Management Tools To Boost Productivity in 2022",
            date: "Nov 28, 2000",
            details: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam sit enim nunc aliquet egestas sed convallis eget. Imperdiet sagittis vitae feugiat pharetra quam..."
        }
    ]

    const pagination = useRef();
    const [isLoaded, setIsLoaded] = useState(false);
    const [offset, setOffset] = useState(0);
    const [perPage] = useState(4);
    const [pageCount, setPageCount] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [number, setNumber] = useState(0);

    useEffect(() => {
        loadData();
        window.scrollTo({
            behavior: "smooth",
            top: scrolltoRef.current.offsetTop,
          });
    }, [offset, number]);


    const setPage = ({ selected }) =>
        setOffset(perPage * selected)

    const loadData = () => {
        setIsLoaded(false);
        setTotalData(blogdata.length)
        const slice = blogdata.slice(offset, offset + perPage);
        if (blogdata.length > 0) {
            setNewBlogList(slice);
            setPageCount(Math.ceil(blogdata.length / perPage));
        }
        setIsLoaded(true)
    }


    return (
        <div className="publicpage">

            <Header />
            {/* Top Banner */}
            <section className="top_banner">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner_flex">
                                <div className="text_Side">
                                    <div className="heading">
                                        <h1>Blog</h1>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                    </div>
                                </div>
                                <div className="blogimg_side">
                                    <img src="images/blog/blog_bnr.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Blog Card */}
            <section className="blog_section">
                <div ref={scrolltoRef} className="innercontainer">
                    <div className="row">
                        {
                            newBlogList.map(item => (
                                <div className="col-lg-6 col-md-6 col_space">
                                <div className="blog_card">
                                    <div className="blog_head">
                                        <div className="blog_img">
                                            <img src={item.img} />
                                        </div>
                                        <div className="blog_desc">
                                            <Link to="/blog-details">
                                                <h4>{item.headeing}</h4>
                                            </Link>
                                            <span>{item.date}</span>
                                            <p>{item.details}</p>
                                        </div>
                                    </div>
                                    <div className="blog_footer">
                                        <h4>Managment</h4>
                                        <div className="comment"><span><i className="fa fa-comment-o"></i> 0</span>    <span>8 Shares</span>    </div>
                                    </div>
                                </div>
                            </div>
                            ))
                        }
                        
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="blog_pagination text-center">
                                {/* <ul className="pagination">
                                    <li><a href="#">1</a></li>
                                    <li><a href="#">2</a></li>
                                    <li><a href="#">3</a></li>
                                    <li><a href="#">4</a></li>
                                    <li><a href="#">5</a></li>
                                    <li><a href="#">...</a></li>
                                    <li><a href="#">Next <i className="fa fa-angle-double-right"></i></a></li>
                                </ul> */}
                                <ReactPaginate
                                    ref={pagination}
                                    pageCount={pageCount}
                                    pageRangeDisplayed={4}
                                    marginPagesDisplayed={0}
                                    onPageChange={setPage}
                                    // previousLabel={<i className="fa fa-angle-left"></i>}
                                    activeClassName="active"
                                    containerClassName="pagination"
                                    // nextLabel={<i className="fa fa-angle-right"></i>}
                                    pageLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    previousLinkClassName="page-link"
                                    pageClassName="page-item"
                                    breakClassName="page-item"
                                    nextClassName="page-item"
                                    previousClassName="page-item"
                                    breakLabel="..."
                                    // breakClassName={"break-me"}
                                    // pageRangeDisplayed={3}
                                    previousLabel={<>&#x3C;</>}
                                    nextLabel={<>&#x3E;</>}
                                />
                            </div>


                        </div>
                    </div>

                </div>
            </section >

            {/* Contact-Us Section */}
            < ContactUsSection />

            {/* Footer Section */}
            {/* < Footer /> */}
            <NewFooter />

        </div >
    )
}

export default BlogsList;