import React from 'react'
import ContactUsSection from './components/ContactUsSection'
import Footer from './components/Footer'
import Header from './components/Header'

const WatchTutorials = () => {
  return (

    <div className="publicpage">

      <Header />

      {/* Top Banner */}

      <section class="top_banner">
            <div class="innercontainer">
                <div class="row">
                    <div class="col-md-12">
                        <div class="banner_flex">
                            <div class="text_Side">
                                <div class="heading">
                                    <h1>Lorem ipsum dolor</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                </div>
                            </div>
                            <div class="img_side">
                                <img src="images/help_guide.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

      <section>
        <div class="innercontainer">
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10">
              <div class="tutorial_body">
                <div class="tutorial_content">
                  <h3>How to stop getting notifications from a particular project or team?</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper sit potenti maecenas ac tristique dui vitae placerat. Enim sed ultrices augue aliquet non pharetra viverra. Duis magna vivamus duis nisi, eu nam sed eleifend. Vel ut diam facilisis volutpat justo, nibh lectus </p>

                  <div class="tutorial_video">
                    <img src="images/tutorial1.png" />
                  </div>
                </div>
                <div class="tutorial_content">
                  <h3>How to stop getting notifications from a particular project or team?</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper sit potenti maecenas ac tristique dui vitae placerat. Enim sed ultrices augue aliquet non pharetra viverra. Duis magna vivamus duis nisi, eu nam sed eleifend. Vel ut diam facilisis volutpat justo, nibh lectus </p>

                  <div class="tutorial_video">
                    <img src="images/tutorial1.png" />
                  </div>
                </div>
                <div class="tutorial_content">
                  <h3>How to stop getting notifications from a particular project or team?</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper sit potenti maecenas ac tristique dui vitae placerat. Enim sed ultrices augue aliquet non pharetra viverra. Duis magna vivamus duis nisi, eu nam sed eleifend. Vel ut diam facilisis volutpat justo, nibh lectus </p>

                  <div class="tutorial_video">
                    <img src="images/tutorial1.png" />
                  </div>
                </div>
                <div class="tutorial_content">
                  <h3>How to stop getting notifications from a particular project or team?</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper sit potenti maecenas ac tristique dui vitae placerat. Enim sed ultrices augue aliquet non pharetra viverra. Duis magna vivamus duis nisi, eu nam sed eleifend. Vel ut diam facilisis volutpat justo, nibh lectus </p>

                  <div class="tutorial_video">
                    <img src="images/tutorial1.png" />
                  </div>
                </div>
              </div>
              <div class="blog_pagination text-center">
                <ul class="pagination">
                  <li><a href="#">1</a></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li><a href="#">4</a></li>
                  <li><a href="#">5</a></li>
                  <li><a href="#">...</a></li>
                  <li><a href="#">Next <i class="fa fa-angle-double-right"></i></a></li>
                </ul>
              </div>

            </div>
            <div class="col-lg-1"></div>
          </div>
        </div>
      </section>

      {/* Contact-Us Section */}
      <ContactUsSection />

      {/* Footer Section */}
      <Footer />
    </div>

  )
}

export default WatchTutorials;