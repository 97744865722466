import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Suspense } from "react";
import { Link } from "react-router-dom";


export const AuthorizedWalkthrough = ({ cmp: Component, ...rest }) => {
  const navigate = useNavigate();

  const checkAuthorization = () => {
    if (!localStorage.getItem("AccessToken")) {
      localStorage.clear();
      navigate("/login"); // Redirect to login if not authorized
    }
  };

  useEffect(() => {
    checkAuthorization();
  }, []);

  return (
    <>
      <div className="signup-form mt-20">
        <div className="publiconboardheader">
          <span className="utlogo">
            <img alt="uniteamImage" src="images/logo.svg" />
          </span>
          <button className="closebtn">
            <Link to="/">
              <img alt="uniteamImage" src="images/mobile/close.svg" />
            </Link>
          </button>
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          <Component {...rest} />
        </Suspense>
      </div>
    </>
  );
};
