import React, { useEffect } from 'react'
import ContactUsSection from '../components/ContactUsSection'
import Footer from '../components/Footer'
import FrequentlyAskQues from '../components/FrequentlyAskQues'
import Header from '../components/Header'

const FeatureTeam = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="publicpage">

            <Header />
            {/* Top Banner */}
            <section className="top_banner">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner_flex">
                                <div className="text_Side">
                                    <div className="heading">
                                        <h4>Teams</h4>
                                        <h1>Collaborate in an organized manner</h1>
                                        <p>It’s easy to invite users and start collaborating with departmental or cross-functional team.</p>
                                        <div className="wrap-btn mt-30"><a className="btn btn-primary btn-lg" href="#/register">Give Uniteam a Try</a></div>
                                    </div>
                                </div>
                                <div className="img_side">
                                    <img alt='feature' src="images/features/fd_team.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Feature */}
            <div className="team-sec Features" id="Features">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">

                            {/* 1 */}
                            <div className="Features-row">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 pull-right">
                                        <div className="Features-text pl mtop">
                                            <div className="Features-heading">
                                                <div className="Features-title">
                                                    <h2 className="">
                                                        Onboard your users
                                                    </h2>
                                                </div>
                                            </div>
                                            <p>
                                                Bringing your teammates to uniteam takes less than a minute.
                                                Send an invitation to users and they will receive a mail with a link to sign up and come on board.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 pull-left">
                                        <div className="Features-img">
                                            <img alt='uniteam' src="images/features/innermodules/Onboard your users.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 2 */}
                            <div className="Features-row">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="Features-text pr mtop">
                                            <div className="Features-heading">
                                                <div className="Features-title">
                                                    <h2 className="">Create unlimited teams </h2>
                                                </div>
                                            </div>
                                            <p> Uniteam has no limits on the number of teams that you can create.
                                                Teams can be based on Departments such as IT, HR, or roles such as managers or any other way want.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="Features-img">
                                            <img alt='uniteam' src="images/meeting-ss.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 3 */}
                            <div className="Features-row">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 pull-right">
                                        <div className="Features-text pl mtop">
                                            <div className="Features-heading">
                                                <div className="Features-title">
                                                    <h2 className="">
                                                        Digest mail to team leader
                                                    </h2>
                                                </div>
                                            </div>
                                            <p>
                                                You can set up a digest mail to go to the team leader every morning containing status updates of all the team members.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 pull-left">
                                        <div className="Features-img">
                                            <img alt='uniteam' src="images/features/innermodules/Digest mail to team leader.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* 4 */}
                            <div className="Features-row">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="Features-text pr mtop">
                                            <div className="Features-heading">
                                                <div className="Features-title">
                                                    <h2 className="">Control task visibility </h2>
                                                </div>
                                            </div>
                                            <p> You can specify if all team members can see each other's tasks or just the task owner and team leader.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="Features-img">
                                            <img alt='uniteam' src="images/features/innermodules/Control task visibility.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Frequently Ask Question */}
            <FrequentlyAskQues />
            {/* Contact Us Section */}
            <ContactUsSection />

            {/* Footer */}
            <Footer />
        </div>
    )
}

export default FeatureTeam;