import React, { useState } from 'react'
import { toast } from 'react-toastify';
import InputSelectCountry from './InputSelectCountry';

const ContactUsSection = () => {
    const ApiBaseURL_url = process.env.REACT_APP_URL;
    const [country, setCountry] = useState("India");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [organization, setOrganization] = useState("");
    const [contactsubmitted, setContactsubmitted] = useState("No");

    const clearFields = () => {
        setCountry("India")
        setName("")
        setName("")
        setPhone("")
        setEmail("")
    }

    const submitContactForm = async (e) => {
        e.preventDefault();
        try {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (name.trim() === "")
                return toast.error("Please enter name");
            else if (email.trim() === "")
                return toast.error("Please enter Email");
            else if (!pattern.test(email))
                return toast.error("Please enter valid email");
            else if (phone === "")
                return toast.error("Please enter phone number")
            else if (organization === "")
                return toast.error("Please enter organization")
            else {
                const requestOptions = {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "name": name,
                        "email": email,
                        "phone": phone,
                        "country": country,
                        "organization": organization,
                        "geolocation": country
                    })
                }
                const response = await fetch(ApiBaseURL_url + 'website/contact', requestOptions)
                const result = await response.json();
                if (response.ok) {
                    if (result.status === "Success") {

                        clearFields();
                        setContactsubmitted("Yes");
                    }
                    else if (response.status === 400) {
                        toast.error(result.errors[0])
                    }
                    else if (response.status === 401) {
                        toast.error("You are not authorized to take this action")
                        window.location.reload()
                        localStorage.clear()
                    }
                }
            }
        } catch (error) {

        }

    }
    return (
        <>
            <section className="cta-sec text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>Need help setting up Uniteam?</h4>
                            <div className="wrap-btn mt-30">
                                <a className="btn btn-outline btn-lg" href="#" data-toggle="modal" data-target="#modal-contact">CONTACT US</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* modal-contact  */}
            <div className="modal fade contact-popup" id="modal-contact">
                <div className="modal-dialog">
                    <div className="modal-content">
                        {contactsubmitted === "No" ?
                            <>
                                <div className="modal-header contactpop">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true"><img src="images/close.png" /></span>
                                    </button>
                                    <h4 className="modal-title">Tailor fit Orchestly for your organization</h4>
                                    <p>
                                        Wondering if Orchestly is the right fit? We have experts who will study how your business
                                        works and see how Orchestly can be implemented for your team.
                                    </p>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <form onSubmit={(e) => submitContactForm(e)}>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Name</label>
                                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control ih40" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Email </label>
                                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control ih40" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Organization</label>
                                                    <input type="text" value={organization} onChange={(e) => setOrganization(e.target.value)} className="form-control ih40" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Phone Number</label>
                                                    <input type="text" value={phone} maxLength="15" onChange={(e) => setPhone(e.target.value.replace(/\D/, ''))} className="form-control ih40" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Country</label>
                                                    <InputSelectCountry className="form-control ih40" value={country} onChange={(e) => setCountry(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary btn-lg">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="acknowledgement">
                                                <button style={{ right: "-100px" }} type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><img alt="uniteamImage" src="images/close.png" /></span></button>
                                                <h4>Thank you for your interest in Uniteam</h4>
                                                <p>We will get back to you soon</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>

    )
}

export default ContactUsSection;