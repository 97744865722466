// contexts/MyContext.js
import React from 'react';

const MyContext = React.createContext();

export const MyContextProvider = ({ children }) => {
    const [contextUser, setContextUser] = React.useState(null);
    const [modules, setModules] = React.useState([]);

    return (
        <MyContext.Provider value={{ contextUser, setContextUser, modules, setModules }}>
            {children}
        </MyContext.Provider>
    );
};

export default MyContext;
