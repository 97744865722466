import React, { useEffect } from 'react'
import ContactUsSection from '../components/ContactUsSection'
import Footer from '../components/Footer'
import FrequentlyAskQues from '../components/FrequentlyAskQues'
import Header from '../components/Header'

const FeaturePerformanceAnalytics = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="publicpage">

            <Header />

            {/* Top Banner */}
            <section class="top_banner">
                <div class="innercontainer">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="banner_flex">
                                <div class="text_Side">
                                    <div class="heading">
                                        <h4>Performance Analytics</h4>
                                        <h1>Measure performance the right way</h1>
                                        <p>Measure performance in realtime as the work happens.</p>
                                        <div class="wrap-btn mt-30"><a class="btn btn-primary btn-lg" href="#/register">Give Uniteam a Try</a></div>
                                    </div>
                                </div>
                                <div class="img_side">
                                    <img src="images/features/fd_analytics.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Feature */}
            <div class="team-sec Features" id="Features">
                <div class="innercontainer">
                    <div class="row">
                        <div class="col-md-12">

                            {/* 1 */}
                            <div class="Features-row">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 pull-right">
                                        <div class="Features-text pl mtop">
                                            <div class="Features-heading">
                                                <div class="Features-title">
                                                    <h2 class="">
                                                    Analyse what you want
                                                    </h2>
                                                </div>
                                            </div>
                                            <p>
                                            You can choose to track performance of individual or the team or the organization as a whole.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 pull-left">
                                        <div class="Features-img">
                                            <img src="images/team-ss.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 2 */}
                            <div class="Features-row">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6">
                                        <div class="Features-text pr mtop">
                                            <div class="Features-heading">
                                                <div class="Features-title">
                                                    <h2 class="">  Proprietry algorithm </h2>
                                                </div>
                                            </div>
                                            <p>A proprietry algorithm based on the data science is the true measure of performance without any external bias.</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <div class="Features-img">
                                            <img src="images/meeting-ss.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 3 */}
                            <div class="Features-row">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 pull-right">
                                        <div class="Features-text pl mtop">
                                            <div class="Features-heading">
                                                <div class="Features-title">
                                                    <h2 class="">
                                                    Leaderboard
                                                    </h2>
                                                </div>
                                            </div>
                                            <p>
                                            Leaderboard ranks team and individuals based on their performance score and bring in the competition and fun
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 pull-left">
                                        <div class="Features-img">
                                            <img src="images/team-ss.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 4 */}
                            <div class="Features-row">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6">
                                        <div class="Features-text pr mtop">
                                            <div class="Features-heading">
                                                <div class="Features-title">
                                                    <h2 class="">Useful metrics to track</h2>
                                                </div>
                                            </div>
                                            <p>Get a comprehensive understanding of your team or employee performance with a rich set of KPIs.</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <div class="Features-img">
                                            <img src="images/meeting-ss.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            {/* Frequently Ask Question */}
            <FrequentlyAskQues />
            {/* Contact Us Section */}
            <ContactUsSection />

            {/* Footer */}
            <Footer />
        </div>
    )
}

export default FeaturePerformanceAnalytics;