import React from "react";
import Searching from "./Searching";
import ProfileOptions from "./ProfileOptions";
import Sidenavbar from "./Sidenavbar";

const Header = ({ page }) => {
  return (
    <>
      <Sidenavbar />
      <div className="header">
        <div className="col-md-4 "></div>
        <div className="col-md-8 ">
          <div className="rightside-menu">
            <Searching page={page} />
            <ProfileOptions />
          </div>
        </div>
      </div>
    </>
  )
}

export default Header;
