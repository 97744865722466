import React, { useEffect } from 'react'
import ContactUsSection from './components/ContactUsSection'
import Footer from './components/Footer'
import Header from './components/Header'
import NewFooter from './components/newFooter'

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        localStorage.setItem("scrollTo", "")
    }, [])
    return (
        <div className="publicpage">

            <Header />

            {/* Top Banner */}
            <section className="top_banner">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner_flex">
                                <div className="text_Side">
                                    <div className="heading">
                                        <h1>About Us</h1>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                                <div className="img_side">
                                    <img src="images/about-us/Discussion.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Our story */}
            <div className="our_story">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page_heading">
                                <h2>Our Story</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum faucibus purus amet, imperdiet nam ac auctor. Molestie enim purus magna odio ac purus. Quis bibendum adipiscing nec purus integer nunc quisque at. Arcu, sit mus volutpat condimentum pellentesque aliquam etiam tellus. Congue ut risus, rutrum mi interdum senectus. Sit vestibulum id tempor, fames aliquam libero in elementum. Sed mattis enim auctor volutpat. Urna aliquam </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Our values  */}
            <div className="our_values" id="Features">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page_heading text-center">
                                <h2>Our Values</h2>
                            </div>
                            <div className="vlaues_top">
                                <div className="values_row">
                                    <div className="values_content">
                                        <h3 className="">Lorem ipsum </h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam sit enim nunc aliquet egestas sed convallis eget. Imperdiet sagittis vitae feugiat pharetra quam...</p>
                                    </div>
                                    <div className="values_img">
                                        <img src="images/card1.png" />
                                    </div>
                                </div>
                                <div className="values_row reverse">
                                    <div className="values_content">
                                        <h3 className="">Lorem ipsum </h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam sit enim nunc aliquet egestas sed convallis eget. Imperdiet sagittis vitae feugiat pharetra quam...</p>
                                    </div>
                                    <div className="values_img">
                                        <img src="images/card1.png" />
                                    </div>
                                </div>
                                <div className="values_row">
                                    <div className="values_content">
                                        <h3 className="">Lorem ipsum </h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam sit enim nunc aliquet egestas sed convallis eget. Imperdiet sagittis vitae feugiat pharetra quam...</p>
                                    </div>
                                    <div className="values_img">
                                        <img src="images/card1.png" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Team */}
            <section className="teams_section">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div className="page_heading text-center">
                                <h2>Team</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Posuere sed vulputate malesuada morbi tellus rhoncus, non rhoncus sed. Natoque tincidunt vulputate facilisis lacus porta. Nunc fusce mi lobortis justo adipiscing egestas </p>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                    <div className='team_space'>
                    <div className="row">
                        <div className="col-md-4 col-sm-4">
                            <div className="team_card">
                                <div className="team_header">
                                    <div className="team_img">
                                        <img src="images/user.jpg" />
                                    </div>
                                    <div className="team_linkedin">
                                        <a href="#" target="_blank"><i className="fa fa-linkedin"></i></a>
                                    </div>
                                </div>
                                <div className="team_desc">
                                    <h4>Vinayak Mishra</h4>
                                    <span>CEO</span>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="team_card">
                                <div className="team_header">
                                    <div className="team_img">
                                        <img src="images/user.jpg" />
                                    </div>
                                    <div className="team_linkedin">
                                        <a href="#" target="_blank"><i className="fa fa-linkedin"></i></a>
                                    </div>
                                </div>
                                <div className="team_desc">
                                    <h4>Vinayak Mishra</h4>
                                    <span>CEO</span>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="team_card">
                                <div className="team_header">
                                    <div className="team_img">
                                        <img src="images/user.jpg" />
                                    </div>
                                    <div className="team_linkedin">
                                        <a href="#" target="_blank"><i className="fa fa-linkedin"></i></a>
                                    </div>
                                </div>
                                <div className="team_desc">
                                    <h4>Vinayak Mishra</h4>
                                    <span>CEO</span>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquam rhoncus tortor tellus. Aliquam </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    </div>
                </div>
            </section>
            {/* Contact-Us Section */}
            <ContactUsSection />

            {/* Footer Section */}
            {/* <Footer /> */}
            <NewFooter />
        </div>
    )
}

export default AboutUs