import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer >
      <div className=" container">
        <div className="top-footer">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4">
                  <h6 className="footer-heading">India</h6>
                  <div className="leftform">
                    <p> Kocreate, Tapasya Corp Heights,<br />3rd Floor, Tower A, Sector 126, Noida(UP), India</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <h6 className="footer-heading">USA</h6>
                  <div className="leftform">
                    <p>555 Republic Dr STE 307<br />Plano, TX 75074, United States</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <h6 className="footer-heading">Email Us</h6>
                  <div className="leftform">
                  
                    <p><span className="fachat"><a href="mailto:support@uniteam.io">support@uniteam.io</a></span><br /><span className="fabriefcase"><a href="mailto:support@uniteam.io">sales@uniteam.io</a></span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-6">
                  <div >
                    <h6 className="footer-heading">Connect With Us</h6>
                    <div className="menu-quick-links">
                      <span><a href="https://www.facebook.com/uniteamapp/" target="_blank" rel="noreferrer">
                        <img alt="uniteamImage" src="images/facebook.png" width="34" /></a></span>
                      <span><a href="https://twitter.com/uniteamapp" target="_blank" rel="noreferrer">
                        <img alt="uniteamImage" src="images/twitter.png" width="34" /></a></span>
                      <span><a href="https://www.linkedin.com/company/uniteamapp" target="_blank" rel="noreferrer">
                        <img alt="uniteamImage" src="images/linkedin.png" width="34" /></a></span>
                      <span><a href="https://www.instagram.com/uniteam.io/" target="_blank" rel="noreferrer">
                        <img alt="uniteamImage" src="images/instagram.png" width="34" /></a></span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <h6 className="footer-heading">Download</h6>
                  <div className="menu-quick-links ">
                    <ul className="menu">
                      <li className="menu-item">
                        <a href="https://play.google.com/store/apps/details?id=com.uniteam.app" target="_blank" rel="noreferrer">
                          <img alt="uniteamImage" src="images/Group2.png" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6">

              © 2021 Uniteam All Rights Reserved | <Link to="/terms-and-conditions">Terms of service</Link> | <Link to="/privacypolicy">Privacy policy</Link>

            </div>
            <div className="col-md-6">
              <div className="poweredby">
                <img src="images/home/powered-by-aws.png" alt="Powered by AWS" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer