import React, { useEffect } from 'react'
import ContactUsSection from '../components/ContactUsSection'
import Footer from '../components/Footer'
import FrequentlyAskQues from '../components/FrequentlyAskQues'
import Header from '../components/Header'

const FeatureEngagementAnalytics = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="publicpage">

            <Header />

            {/* Top Banner */}
            <section class="top_banner">
                <div class="innercontainer">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="banner_flex">
                                <div class="text_Side">
                                    <div class="heading">
                                        <h4>Engagement Analytics</h4>
                                        <h1>Build a happy and engaged team</h1>
                                        <p>Actionable insights to measure employee engagement in realtime and take proactive action to build a happy and committed and reduce attrition.</p>
                                        <div class="wrap-btn mt-30"><a class="btn btn-primary btn-lg" href="#/register">Give Uniteam a Try</a></div>
                                    </div>
                                </div>
                                <div class="img_side">
                                    <img alt='feature' src="images/features/fd_analytics.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Feature */}
            <div class="team-sec Features" id="Features">
                <div class="innercontainer">
                    <div class="row">
                        <div class="col-md-12">

                            {/* 1 */}
                            <div class="Features-row">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 pull-right">
                                        <div class="Features-text pl mtop">
                                            <div class="Features-heading">
                                                <div class="Features-title">
                                                    <h2 class="">
                                                        Measure employee happiness
                                                    </h2>
                                                </div>
                                            </div>
                                            <p>
                                                A simple employee mood tracker will help you understand how your team is feeling today.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 pull-left">
                                        <div class="Features-img">
                                            <img src="images/team-ss.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 2 */}
                            <div class="Features-row">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6">
                                        <div class="Features-text pr mtop">
                                            <div class="Features-heading">
                                                <div class="Features-title">
                                                    <h2 class=""> Track employee engagement </h2>
                                                </div>
                                            </div>
                                            <p>Understand what is causing employee disengagement and take timely action before the problem goes out of head. </p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <div class="Features-img">
                                            <img src="images/meeting-ss.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {/* 3 */}
                            <div class="Features-row">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 pull-right">
                                        <div class="Features-text pl mtop">
                                            <div class="Features-heading">
                                                <div class="Features-title">
                                                    <h2 class="">
                                                        In-built engagement survey
                                                    </h2>
                                                </div>
                                            </div>
                                            <p>
                                                Uniteam comes preconfigured with an engagement survey based on the latest research in the field however you are free to customize it as per your need.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 pull-left">
                                        <div class="Features-img">
                                            <img src="images/team-ss.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>


            {/* Frequently Ask Question */}
            <FrequentlyAskQues />
            {/* Contact Us Section */}
            <ContactUsSection />

            {/* Footer */}
            <Footer />
        </div>
    )
}

export default FeatureEngagementAnalytics;