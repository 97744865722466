import React from 'react';
import { useNavigate } from 'react-router-dom';

const SettingsHeader = () => {
    const navigate = useNavigate();

    const goToTasksPath = () => {
        navigate("/tasks");
    };

    return (
        <div className="setting-header">
            <h4 className="pull-left">Settings</h4>
            <div className="pull-right">
                <a onClick={goToTasksPath}>
                    <img alt="uniteamImage" src="/images/setting/cross-icon.png" className="topclose" />
                </a>
            </div>
            <div className="clearfix"></div>
        </div>
    );
};

export default SettingsHeader;
