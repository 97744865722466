import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import ContactUsSection from './components/ContactUsSection'
import Footer from './components/Footer'
import Header from './components/Header'
import NewFooter from './components/newFooter'

const HelpGuide = () => {
  const [activeTab, setActiveTab] = useState("fundamentals")

  const activeSideBar = (value) => {
    if (value === activeTab) {
      setActiveTab("")
    } else {
      setActiveTab(value)
    }
  }
  return (

    <div className="publicpage">
      <Header />
      {/* Top Banner */}
      <section className="top_banner">
        <div className="innercontainer">
          <div className="row">
            <div className="col-md-12">
              <div className="banner_flex">
                <div className="text_Side">
                  <div className="heading">
                    <h1>Help Guide</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                  </div>
                </div>
                <div className="img_side">
                  <img src="images/help_guide.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="help_guide">
        <div className="innercontainer">
          <div className="row">
            <div className="col-md-3">
              <div className="tab_list">
                <ul className="tab_list_ul">
                  <li className="main_li"><a className="main_a">HELP GUIDE</a></li>

                  {/* fundamentals */}
                  <li className="main_li">
                    <NavLink to="#" onClick={() => activeSideBar("fundamentals")} className="main_a">FUNDAMENTALS <i className={activeTab === "fundamentals" ? "fa fa-angle-up" : "fa fa-angle-down"}></i></NavLink>
                    {activeTab === "fundamentals"
                      ?
                      <ul className="sub_ul">
                        <li className="sub_li"><a href="">Lorem ipsum</a></li>
                        <li className="sub_li"><a href="">Lorem ipsum</a></li>
                        <li className="sub_li"><a href="">Lorem ipsum</a></li>
                        <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      </ul>
                      : ""
                    }
                  </li>

                  {/* Task */}
                  <li className="main_li"><NavLink to="#" onClick={() => activeSideBar("task")} className="main_a">Task <i className={activeTab === "task" ? "fa fa-angle-up" : "fa fa-angle-down"}></i></NavLink></li>
                  {activeTab === "task"
                    ?
                    <ul className="sub_ul">
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                    </ul>
                    : ""
                  }
                  {/* Team */}
                  <li className="main_li"><NavLink to="#" onClick={() => activeSideBar("team")} className="main_a">TEAM <i className={activeTab === "team" ? "fa fa-angle-up" : "fa fa-angle-down"}></i></NavLink></li>
                  {activeTab === "team"
                    ?
                    <ul className="sub_ul">
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                    </ul>
                    : ""
                  }
                  {/* Project */}
                  <li className="main_li"><NavLink to="#" onClick={() => activeSideBar("project")} className="main_a">Project <i className={activeTab === "project" ? "fa fa-angle-up" : "fa fa-angle-down"}></i></NavLink></li>
                  {activeTab === "project"
                    ?
                    <ul className="sub_ul">
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                    </ul>
                    : ""
                  }


                  {/* Analytics */}
                  <li className="main_li"><NavLink to="#" onClick={() => activeSideBar("analytics")} className="main_a">Analytics <i className={activeTab === "analytics" ? "fa fa-angle-up" : "fa fa-angle-down"}></i></NavLink></li>
                  {activeTab === "analytics"
                    ?
                    <ul className="sub_ul">
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                    </ul>
                    : ""
                  }
                  {/* UPGRADE & BILLING */}
                  <li className="main_li"><NavLink to="#" onClick={() => activeSideBar("upgradebilling")} className="main_a">UPGRADE & BILLING <i className={activeTab === "upgradebilling" ? "fa fa-angle-up" : "fa fa-angle-down"}></i></NavLink></li>
                  {activeTab === "upgradebilling"
                    ?
                    <ul className="sub_ul">
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                      <li className="sub_li"><a href="">Lorem ipsum</a></li>
                    </ul>
                    : ""
                  }
                </ul>
              </div>
            </div>
            <div className="col-md-9">
              <div className="help_guide_body">
                <div className="help_guide_content">
                  <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget morbi id morbi integer habitant ultrices. Euismod dictum magna eget suspendisse. Sit viverra a quis non, nisi, integer. Sed eu ultrices nisi, mauris urna quis fames dictumst ac. Nec tortor convallis vivamus lectus odio vitae massa. Felis laoreet id blandit placerat sapien consequat mauris sagittis.
                    Id ornare vivamus molestie augue sit at integer aliquam. Sit feugiat orci porttitor viverra arcu sed urna. Quis nam turpis consectetur et nec adipiscing ut id natoque. Ornare faucibus dui vel cursus. Volutpat integer orci netus cras tortor eu, eget et leo.
                  </p>
                  <div className="help_bnr">
                    <img src="images/blog231.png" width="100%" />
                  </div>
                  <div className="help_tip">
                    <div className="tip_icon"><img src="images/check.png" /></div>
                    <div className="tip_text"><b>Uniteam Tip:</b> dolor sit amet, consectetur adipiscing elit. Nullam nullam tincidunt sed sed sit interdum. Turpis dictum nulla est congue at tellus duis viverra fringilla.</div>
                  </div>
                </div>

                <div className="help_guide_content">
                  <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget morbi id morbi integer habitant ultrices. Euismod dictum magna eget suspendisse. Sit viverra a quis non, nisi, integer. Sed eu ultrices nisi, mauris urna quis fames dictumst ac. Nec tortor convallis vivamus lectus odio vitae massa. Felis laoreet id blandit placerat sapien consequat mauris sagittis.
                    Id ornare vivamus molestie augue sit at integer aliquam. Sit feugiat orci porttitor viverra arcu sed urna. Quis nam turpis consectetur et nec adipiscing ut id natoque. Ornare faucibus dui vel cursus. Volutpat integer orci netus cras tortor eu, eget et leo.
                  </p>
                  <div className="help_bnr">
                    <img src="images/blog231.png" width="100%" />
                  </div>
                  <div className="help_tip">
                    <div className="tip_icon"><img src="images/check.png" /></div>
                    <div className="tip_text"><b>Uniteam Tip:</b> dolor sit amet, consectetur adipiscing elit. Nullam nullam tincidunt sed sed sit interdum. Turpis dictum nulla est congue at tellus duis viverra fringilla.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact-Us Section */}
      {/* <ContactUsSection /> */}

      {/* Footer Section */}
      {/* <Footer /> */}
      <NewFooter />
    </div>

  )
}

export default HelpGuide;