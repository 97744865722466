import React, { useEffect } from 'react'
import ContactUsSection from './components/ContactUsSection'
import Footer from './components/Footer'
import Header from './components/Header'
import NewFooter from './components/newFooter'

const BlogDetails = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        localStorage.setItem("scrollTo", "")
    }, [])

    return (
        <div className="publicpage">
            <Header />
            {/* Top Banner */}
            <section className="top_banner bloginner">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-10">
                            <div className="blog_inner_heading">
                                <h5>COMMUNICATION</h5>
                                <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h1>
                                <p>By <span className="blogby">Namita Agrawal</span> <span className="blogdate">Apr 27, 2022</span></p>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                    </div>
                </div>
            </section>

            <section>
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-10">
                            <div className="blog_inner_img">
                                <img src="images/blog/blog1.png" />
                            </div>
                            <div className="blog_inner_body">
                                <p>Managing multiple projects can be a daunting challenge. Especially for a beginner, it may feel like trekking a steep mountain with a constant fear of falling.But, don’t companies do it all the time? And that too successfully?Well, don't Jack Dorsey manage both Twitter and Square, Elon Musk - both Tesla and SpaceX at the same time? And then there is Richard Branson and others who are managing multiple companies, let alone projects.What we are intending to say here is that managing multiple </p>
                                <h3>Challenges Faced While Managing Multiple Projects</h3>
                                <p>
                                    Managing multiple projects isn't impossible but it sure is tricky for both novice and polished project managers. Here are some of the common challenges faced while executing projects in parallel:
                                    Establishing visibility across projects
                                    No way to schedule projects, keeping resource allocation in mind
                                    Making sure, project timelines don’t overlap.
                                    It takes herculean efforts to track progress across projects and yet, things fall through the cracks.
                                    Mis-communication and misalignment among stakeholders
                                    Hindrance in communication flow ends up delaying deliverables.
                                    Prioritizing tasks seems like shooting an arrow in the dark
                                    Team members lack clarity on why, how, and when to proceed with the project/task
                                    An unbalanced workload that can be stressful for the team.
                                    All these obstacles cause project delays along with a stressful team trying to fix holes while the ship is already half under the water. This is no way to manage multiple projects. Here’s how you can manage them effectively.
                                </p>
                                <h3>How to Manage Multiple Projects Simultaneously? - 9 Strategies</h3>
                                <h3>
                                    1. Form a proper organizational hierarchy
                                </h3>
                                <p>Here are 9 strategies that can  help you effectively manage multiple projects simultaneously:</p>
                                <p>
                                    TLDR: Form teams based on their capacity of executing multiple and varied project types in parallel.Suppose you have 50 projects running in parallel. Your first step should be to figure out how many teams will be required to complete these projects. Here are a few things to consider before forming your teams:
                                    Are you executing different types of projects?
                                    If so, group similar projects together. This would give you a better understanding of the types of projects the organization handles.
                                    What is the frequency of each project type?
                                    How many teams would be required to execute each project type based on the number of projects they can execute in parallel?
                                    Finally, decide which team will be taking care of which project type, and pick a lead for each team. Establishing a team lead, and the type of project is critical to ensure the team has a clear understanding of responsibility and reporting hierarchy.
                                </p>
                                <div className="bi_img">
                                    <img src="images/blog/blog231.png" />
                                </div>



                                <hr className="bloghr" />
                                <p className="text-center likeblog">
                                    Like this Blog? Spread the word.
                                </p>
                                <div className="text-center">
                                    <span><a href="https://www.facebook.com/uniteamapp/" target="_blank"><img src="images/facebook.png" width="30" /></a></span>
                                    <span><a href="https://twitter.com/uniteamapp" target="_blank"><img src="images/twitter.png" width="30" /></a></span>
                                    <span><a href="https://www.linkedin.com/company/uniteamapp" target="_blank"><img src="images/linkedin.png" width="30" /></a></span>
                                    <span><a href="https://www.instagram.com/uniteam.io/" target="_blank"><img src="images/instagram.png" width="30" /></a></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                    </div>
                </div>
            </section>
            {/* Contact Us Section */}
            <ContactUsSection />

            {/* <Footer /> */}
            <NewFooter />

        </div>
    )
}

export default BlogDetails