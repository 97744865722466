import React from 'react'

function PleaseUpgradePlan() {
 
    return (
        <>
        <div className="pagenotfound">
            <div>
                <h3>You are not authorized to view this page</h3>
                <p>to access this page you need to upgrade your plan</p>
                <button class="btn btn-theme mt-10" data-toggle="modal"> Upgrade Plan</button>
                <button class="btn btn-theme mt-10" data-toggle="modal">Your Tasks</button>
            </div>
        </div>
        </>
    )
}

export default PleaseUpgradePlan;



