import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
//import MyContext from '../contexts/MyContext';
import { toast } from 'react-toastify';
import ServiceOnboarding from '../services/ServiceOnboarding';

function Sidenavbar() {
    //const ApiBaseURL_url = process.env.REACT_APP_URL;
    const role = localStorage.getItem('UserType');
    // const loginUserInfo = JSON.parse(localStorage.getItem('LoginUserInfo'));
    // const allowAnalytics = loginUserInfo?.allowanalytics;
    const allowAnalytics = JSON.parse(localStorage.getItem('LoginUserInfo'))?.allowanalytics || false;
    const [Modules, setModules] = useState([]);
    const [activeMenu, setActiveMenu] = useState("");
    const apifunction = new ServiceOnboarding();
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname.replace(/\/$/, "");

    // Determine the active menu based on the current pathname
    useEffect(() => {
        if (!localStorage.getItem('UserID')) {
            navigate("/");
        } else {
            //if pathname is not in the list, set the first menu as active
            if (pathname === "/") setActiveMenu("tasks");
            else if (pathname.match(/tasks/)) setActiveMenu("tasks");
            else if (pathname.match(/teams/)) setActiveMenu("teams");
            else if (pathname.match(/meetings/)) setActiveMenu("meetings");
            else if (pathname.match(/analytics/) || pathname.match(/engagement/)) setActiveMenu("analytics");
            else if (pathname.match('settings/profile-setting')) setActiveMenu("profile-setting");
            else if (pathname.match(/project-tasks/)) setActiveMenu("projects");
        }
    }, [pathname, navigate]);

    useEffect(() => {
        const loadData = async () => {
            if (window.performance && performance.navigation.type === 1) {
                await loadModules();
            }
        };

        loadData();
    }, []);

    // Redirect if the user doesn't have access to certain modules
    useEffect(() => {
        if (Modules.length > 0) {
            const checkIfDataAvailable = (module_name) => {
                return Modules.some(item => item.module_name === module_name);
            };

            if (pathname === "/project/all" && !checkIfDataAvailable("Projects")) {
                navigate("/please-upgrade-plan");
            } else if (pathname === "/analytics" && !checkIfDataAvailable("Performance Analytics")) {
                navigate("/please-upgrade-plan");
            } else if (pathname.match("/engagement/") && !checkIfDataAvailable("Engagement Analytics")) {
                navigate("/please-upgrade-plan");
            }
        }
    }, [pathname, Modules, navigate]);

    const loadModules = async () => {
        try {
            const req = {
                Email: localStorage.getItem("Email")
            };

            const result = await apifunction.UserProfile(req);
            if (result) {
                const selectedPortalIndex = Number(localStorage.getItem("selectedPortalIndex"));
                const selectedPortal = result.portals[selectedPortalIndex];
                localStorage.setItem("Totalmembers", selectedPortal.totalMembers || 0);
                //localStorage.setItem('UserPhoto', result.photoUrl);
                if (selectedPortal.plan?.modules?.length > 0) {
                    console.log("selectedPortal.plan.modules", selectedPortal.plan.modules);
                    setModules(selectedPortal.plan.modules);
                } else {
                    toast.error("You don't have permissions to access any module. Please contact admin.");
                    setTimeout(() => {
                        localStorage.clear();
                        navigate("/");
                    }, 1000);
                }
            }
        } catch (error) {
            toast.error('Failed to load modules. Please try again later.');
        }
    };

    return (
        <div className="fiexdsidenav" style={{ overflow: 'hidden' }}>
            <div className="logo" onClick={() => navigate("/")}>
                <img alt="image" src="/images/icons-v2/uniteam-Icon-white.svg" className="inner-logo" />
            </div>
            <div className="menu-list">
                {Modules && Modules.length > 0 ? Modules.map((item, index) => (
                    <div key={index}>
                        {item.module_name === "Tasks" && (
                            <Link id={index} className={activeMenu === "tasks" ? "active" : ""} to="/tasks">
                                <img alt="" src="/images/icons-v2/sidebar-tasks2.svg" className="menu-icon" />Tasks
                            </Link>
                        )}
                        {item.module_name === "Meetings" && (
                            <Link id={index} className={activeMenu === "meetings" ? "active" : ""} to="/meetings">
                                <img alt="" src="/images/icons-v2/sidebar-meetings2.svg" className="menu-icon" />Meetings
                            </Link>
                        )}
                        {item.module_name === "Teams" && (
                            <Link id={index} className={activeMenu === "teams" ? "active" : ""} to={`/teams`}>
                                <img alt="" src="/images/icons-v2/sidebar-teams2.svg" className="menu-icon" style={{ width: "27px" }} />Teams
                            </Link>
                        )}
                        {item.module_name === "Performance Analytics" && (role === "Admin" || allowAnalytics) && (
                            <Link id={index} className={activeMenu === "analytics" ? "active" : ""} to="/analytics">
                                <img alt="" src="/images/icons-v2/sidebar-analytics2.svg" className="menu-icon" style={{ width: "28px" }} />Analytics
                            </Link>
                        )}
                        {item.module_name === "Engagement Analytics" && (role === "Admin") && (
                            <Link id={index} className={activeMenu === "profile-setting" ? "active" : ""} to="/settings/storage">
                                <img alt="" src="/images/icons-v2/settings-sliders2.svg" className="menu-icon" style={{ width: "27px" }} />
                                Settings
                            </Link>
                        )}

                    </div>
                )) : (<>
                    <Link to="/tasks" className={activeMenu === "tasks" ? "active" : ""}>
                        <img alt="" src="/images/icons-v2/sidebar-tasks2.svg" className="menu-icon" />Tasks
                    </Link>
                    <Link to="/meetings" className={activeMenu === "meetings" ? "active" : ""}>
                        <img alt="" src="/images/icons-v2/sidebar-meetings2.svg" className="menu-icon" />Meetings
                    </Link>
                    <Link to={`/teams`} className={activeMenu === "teams" ? "active" : ""}>
                        <img alt="" src="/images/icons-v2/sidebar-teams2.svg" className="menu-icon" style={{ width: "27px" }} />Teams
                    </Link>
                    {(role === "Admin" || allowAnalytics) && (
                        <Link to="/analytics" className={activeMenu === "analytics" ? "active" : ""}>
                            <img alt="" src="/images/icons-v2/sidebar-analytics2.svg" className="menu-icon" style={{ width: "28px" }} />Analytics
                        </Link>
                    )}
                    {role === "Admin" && (
                    <Link to="/settings/storage" className={activeMenu === "profile-setting" ? "active" : ""}>
                        <img alt="" src="/images/icons-v2/settings-sliders2.svg" className="menu-icon" style={{ width: "27px" }} />
                        Settings
                    </Link>)}
                </>
                )}
            </div>
        </div>
    );
}

export default React.memo(Sidenavbar);
