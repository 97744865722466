import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const Header = ({ featureRef, pricingRef }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  function toggleNavCollapse() {
    setIsNavCollapsed(!isNavCollapsed);
  }

  function scrolltoFeature() {
    if (location.pathname !== "/") {
      localStorage.setItem("scrollTo", "Feature");
      navigate("/");
    } else {
      localStorage.setItem("scrollTo", "");
      if (featureRef.current) {
        featureRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  function scrolltoPricing() {
    if (location.pathname !== "/") {
      localStorage.setItem("scrollTo", "Pricing");
      navigate("/");
    } else {
      localStorage.setItem("scrollTo", "");
      if (pricingRef.current) {
        pricingRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  useEffect(() => {
    if (location.pathname !== "/") {
      // any additional logic you want to execute on route change
    }
  }, [location.pathname]);

  return (
    <>
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="innercontainer d-flex-space-between">
          <div className="navbar-header">
            <button 
              className={`navbar-toggle collapsed ${isNavCollapsed ? '' : 'open'}`}
              onClick={toggleNavCollapse}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className={`icon-bar ${isNavCollapsed ? '' : 'hidden'}`}></span>
              <span className={`icon-bar ${isNavCollapsed ? '' : 'hidden'}`}></span>
              <span className={`icon-bar ${isNavCollapsed ? '' : 'hidden'}`}></span>
              <span className={`icon-cross ${isNavCollapsed ? 'hidden' : ''}`}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </span>
            </button>

            <a className="navbar-brand d-flex" onClick={() => localStorage.setItem("scrollTo", "")}>
              <img alt="image" 
              // src="images/uniteamlogo.svg" 
              src="images/icons-v2/uniteam-Logo-new.svg"
              className='unilogo' /> 
              {/* <span className='unicon '>Uniteam </span> */}
            </a>
          </div>

          <div className={`navbar-collapse ${isNavCollapsed ? 'collapse' : ''}`}>
            <ul className="nav navbar-nav navbar-right rightnav">
              {/* <li className='mt-08rem nav-fontlink nav-item'>
                <a className='text-black' onClick={() => { scrolltoFeature(); setIsNavCollapsed(true); }}>Features</a>
              </li>
              <li className='mt-08rem nav-fontlink nav-item'>
                <a className='text-black' onClick={() => { scrolltoPricing(); setIsNavCollapsed(true); }}>Pricing</a>
              </li>
              <li className='mt-08rem nav-fontlink nav-item'>
                <Link className='text-black' to="/about-us" onClick={() => setIsNavCollapsed(true)}>About Us</Link>
              </li> */}
              <li className='mt-08rem nav-fontlink nav-item show-in-mob'>
                <Link className='text-black' to="/login" onClick={() => setIsNavCollapsed(true)}>Sign In</Link>
              </li>
              <li>
                <Link to="/register" className="btn btn-primary mrl navlink w-106 text-white show-in-mob" onClick={() => setIsNavCollapsed(true)}>
                  <span>Try it free</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className='hide-in-mob'>
            <ul className="nav navbar-nav navbar-right rightnav">
              <li className='mt-08rem nav-fontlink nav-item'>
                <Link className='text-black' to="/login" onClick={() => setIsNavCollapsed(true)}>Sign In</Link>
              </li>
              <li>
                <Link to="/register" className="btn btn-primary mrl navlink w-106 text-white" onClick={() => setIsNavCollapsed(true)}>
                  <span>Try it free</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
