export const msalConfig = {
    auth: {
        clientId: "134686ba-62f6-4bd4-ad34-2dc53df74163",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: "http://localhost:3000",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    }
};

export const loginRequest = {
    scopes: [
        'User.Read',
        'Calendars.ReadWrite',
        'OnlineMeetings.ReadWrite',
        'openid',
        'profile',
        'email'
      ]
};
