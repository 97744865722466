import React, { useState, useEffect } from 'react'
import MyContext from './MyContext';

export default function MyProvider(props) {
    const [contextUser, setContextUser_local] = useState(JSON.parse(localStorage.getItem("LoginUserInfo")));
    const [Modules, setLocalModules] = useState([]);
    const [a,setA]=useState([])
    useEffect(()=>{
        setLocalModules(a)
    },[a])
    return (
        <MyContext.Provider
            value={{
                Contextuser: { ...contextUser },
                Modules,
                setContextUser: userinfo => {
                    setContextUser_local(userinfo)
                },
                setModules: m => {
                    setA(m)
                }
                
            }}
        >
            {props.children}
        </MyContext.Provider>
    )
}
