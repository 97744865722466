import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import NewFooter from './components/newFooter';

const Support = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        localStorage.setItem("scrollTo", "");
    }, []);

    return (
        <div className="publicpage">
            <Header />

            {/* Top Banner */}
            <section className="top_banner">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner_flex">
                                <div className="text_Side">
                                    <div className="heading">
                                        <h1>Support</h1>
                                        <p>Welcome to Uniteam Support! We’re here to help you make the most of Uniteam, whether you're just getting started or looking for advanced tips.</p>
                                        <div className="banner_btn">
                                            <Link className="btn btn_themeline" to="/watch-tutorials">Watch tutorials</Link>
                                            <Link className="linkbtn mt-20" to="/help-guide">Read the help guides</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="img_side">
                                    <img src="images/about-us/Discussion.svg" alt="Support" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Getting Started Section */}
            <div className="support_info mt-20">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page_heading">
                                <h2>Getting Started with Uniteam</h2>
                                <p>If you're new to Uniteam, here are some key steps to help you get up and running:</p>
                                <ul>
                                    <li><strong>Sign Up & Create Your Account:</strong> Download the Uniteam app or visit our website to create your account. You can sign up using your email or with Google/Microsoft accounts.</li>
                                    <li><strong>Set Up Your Team:</strong> Organize teams by departments, projects, or custom structures. Add members via email and assign appropriate roles.</li>
                                    <li><strong>Start Managing Tasks:</strong> Use the 'Task' menu to create, assign, and track tasks with due dates and priorities. You can delegate tasks and get notifications as deadlines approach.</li>
                                    <li><strong>Schedule Meetings:</strong> Create meetings and invite participants with built-in scheduling tools, integrating with Zoom, Microsoft Teams, and Google Meet.</li>
                                    <li><strong>Leverage the AI Assistant:</strong> Use Uniteam’s AI Assistant to simplify workflows via voice or text commands to create tasks, get updates, and schedule meetings.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Frequently Asked Questions */}
            <div className="faq_section">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page_heading">
                                <h2>Frequently Asked Questions (FAQs)</h2>
                                <ul>
                                    <li><strong>How do I reset my password?</strong> Go to the login page and click on “Forgot Password.” Enter your registered email and we’ll send you a password reset link.</li>
                                    <li><strong>How do I add or remove team members?</strong> Navigate to your team settings to add or remove members. To add, enter their email and assign a role. To remove, click the “Remove” button next to their name.</li>
                                    <li><strong>How can I upgrade my subscription?</strong> Visit 'Account Settings' and select the 'Subscription' tab. Choose the plan that suits your needs and billing will adjust accordingly.</li>
                                    <li><strong>Can I integrate Uniteam with other tools?</strong> Yes, Uniteam integrates with third-party apps like Slack, Zoom, Google Calendar, and Microsoft Teams. Check the 'Integrations' tab in your settings for the full list.</li>
                                    <li><strong>What should I do if I encounter a technical issue?</strong> If you're experiencing issues, contact our support team using the information in the 'Contact Us' section below. Include details like screenshots and steps to reproduce the issue.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Helpful Resources Section */}
            <div className="helpful_resources">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page_heading">
                                <h2>Helpful Resources</h2>
                                <ul>
                                    <li><Link to="/getting-started-guide">Getting Started with Uniteam</Link></li>
                                    <li><Link to="/task-management">Task Management Best Practices</Link></li>
                                    <li><Link to="/integrations">Integrating Uniteam with Your Favorite Apps</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Support Form */}
            <div className="supoort_form">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page_heading">
                                <h2>Send us a message to get detailed help</h2>
                            </div>
                            <div className="support_box">
                                <div className="support_feild">
                                    <div className="support_text">
                                        <h3>What do you need help with? </h3>
                                        <p>This helps make sure you get the right answer fast.</p>
                                    </div>
                                    <div className="form-group">
                                        <select className="form-control">
                                            <option>Technical Issue</option>
                                            <option>Billing Question</option>
                                            <option>Account Setup</option>
                                            <option>Other</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="support_feild">
                                    <div className="support_text">
                                        <h3>What’s your question, comment, or issue?</h3>
                                        <p>Share all the details. The more we know, the better we can help you.</p>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" rows="10" placeholder="Type your message here..." spellCheck="false"></textarea>
                                    </div>
                                </div>
                                <div className="support_feild">
                                    <div className="support_text">
                                        <h3>Send us a file, screenshot, or document</h3>
                                        <p>Hold the shift key to select multiple files.</p>
                                    </div>
                                    <div className="form-group">
                                        <div className="file-input">
                                            <input type="file" name="file-input" id="file-input" className="file-input__input" />
                                            <label className="file-input__label" htmlFor="file-input"><span>Choose Files</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="support_feild">
                                    <div className="support_text">
                                        <h3>What’s your email address?</h3>
                                        <p>This is where we’ll get back to you. Double check that it’s right.</p>
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder="Enter your email address..." />
                                    </div>
                                </div>

                                <div className="wrap-btn text-center"><a className="btn btn_theme" href="#">Submit this support request</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Live Chat Support */}
            <div className="live_chat_support">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page_heading">
                                <h2>Live Chat Support</h2>
                                <p>Need immediate assistance? Our customer service team is available to help via live chat from Monday to Friday, 9 AM to 6 PM (IST). You can access the live chat feature directly through the app or by visiting our website.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact Us */}
            <div className="contact_us">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page_heading">
                                <h2>Contact Us</h2>
                                <p>If you need further assistance, feel free to reach out:</p>
                                <ul>
                                    <li><strong>Email:</strong> <a href="mailto:support@uniteam.ai" style={{ color: "#028090" }}>support@uniteam.ai</a></li>
                                    <li><strong>Business Hours:</strong> Monday to Friday 9 AM - 6 PM (IST)</li>
                                    <li><strong>Urgent Issues:</strong> We aim to respond within 24 hours for urgent issues.</li>
                                </ul>
                                <p>Thank you for choosing Uniteam! We’re excited to help you boost your team’s productivity and efficiency.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Footer /> */}
            <NewFooter/>
        </div>
    );
}

export default Support;
