import React, { useEffect } from 'react'
import ContactUsSection from '../components/ContactUsSection'
import Footer from '../components/Footer'
import FrequentlyAskQues from '../components/FrequentlyAskQues'
import Header from '../components/Header'

const FeatureTask = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="publicpage">
            <Header />
            {/* Top Banner */}
            <section className="top_banner">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner_flex">
                                <div className="text_Side">
                                    <div className="heading">
                                        <h4>Task</h4>
                                        <h1>
                                            The place where work happens
                                        </h1>
                                        <p>
                                            Stay organized and keep track of individual and team tasks without hassle on Uniteam.
                                        </p>
                                        <div className="wrap-btn mt-30"><a className="btn btn-primary btn-lg" href="#/register">Give Uniteam a Try</a></div>
                                    </div>
                                </div>
                                <div className="img_side">
                                    <img alt='' src="images/features/fd_task.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Feature */}
            <div className="team-sec Features" id="Features">
                <div className="innercontainer">
                    <div className="row">
                        <div className="col-md-12">
                            {/* 1 */}
                            <div className="Features-row">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 pull-right">
                                        <div className="Features-text pl mtop">
                                            <div className="Features-heading">
                                                <div className="Features-title">
                                                    <h2 className="">
                                                        Create Tasks & Assign them to your team
                                                    </h2>
                                                </div>
                                            </div>
                                            <p>
                                                It’s easy to create a task for yourself or assign to one or more of your teammates.
                                                It’s easy to update the status and track what has been done so far.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 pull-left">
                                        <div className="Features-img">
                                            <img src="images/features/innermodules/Create Tasks - Assign them to your team.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 2 */}
                            <div className="Features-row">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="Features-text pr mtop">
                                            <div className="Features-heading">
                                                <div className="Features-title">
                                                    <h2 className="">
                                                        Stay organized and never miss a deadline
                                                    </h2>
                                                </div>
                                            </div>
                                            <p>
                                                In uniteam tasks are well organized into buckets such as overdue, today so that you never loose focus of your priorities. List view and Calendar view will help you to track the work the way you want.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <div className="Features-img">
                                            <img src="images/features/innermodules/Stay organized and never miss a deadline.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* 3 */}
                            <div className="Features-row">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 pull-right">
                                        <div className="Features-text pl mtop">
                                            <div className="Features-heading">
                                                <div className="Features-title">
                                                    <h2 className="">
                                                    Single place for all your work
                                                    </h2>
                                                </div>
                                            </div>
                                            <p>
                                                You task screen has everything to work on whether its your own to do list, task delegated by your manager, work assign to you in a meeting or project activity and issues that you need to take care of.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 pull-left">
                                        <div className="Features-img">
                                            <img src="images/team-ss.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {/* 4 */}
                            <div className="Features-row">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 pull-left">
                                        <div className="Features-text pl mtop">
                                            <div className="Features-heading">
                                                <div className="Features-title">
                                                    <h2 className="">
                                                    Task Activities
                                                    </h2>
                                                </div>
                                            </div>
                                            <p>
                                            You can add much more information to your task such as Attachments, Priority and comments. A complete trail of action is automatically maintained by the system.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 pull-right">
                                        <div className="Features-img">
                                            <img src="images/features/innermodules/Task Activities.png" width="100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Frequently Ask Question */}
            <FrequentlyAskQues />

            {/* Contact Us Section */}
            <ContactUsSection />

            {/* Footer */}
            <Footer />
        </div>
    )
}

export default FeatureTask;